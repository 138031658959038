<template>
    <div>
        <h1 class="page__title">Создать документ</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6">
                    <label for="form-title" class="form-label">Название<sup class="text-theme-6"> *</sup></label>
                    <input
                        id="form-title"
                        v-model="form.title"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        :class="{ 'form-input-error': v$.form.title.$errors.length }"
                        required
                    />
                </div>

                <div class="col-span-2 flex items-center pt-6 mb-5">
                    <input
                        id="form-published"
                        v-model="form.published"
                        class="show-code form-check-switch flex-none mr-0 ml-3"
                        type="checkbox"
                    />
                    <label for="form-published" class="form-check-label">Опубликовано</label>
                </div>

                <div class="col-span-6">
                    <label for="form-file" class="form-label">Файл<sup class="text-theme-6"> *</sup></label>

                    <FileUpload
                        id="create-profile-documents-file-upload"
                        :files="form.file"
                        :error="v$.form.file.$errors.length"
                        @on-files-change="handleFilesChange"
                        @on-files-remove="handleFilesRemove"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'content-profile-documents' }" class="btn btn-secondary w-24 mr-2 mb-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import FileUpload from '@/components/base/file-upload/FileUpload.vue';
import { formPreparation } from '@/utils/object-to-form-data';

export default {
    name: 'ProfileDocumentCreate',
    components: { Preloader, FileUpload },
    mixins: [formPreparation, errorResponse],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                file: { required },
            },
        };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                published: false,
                file: [],
            },
        };
    },
    methods: {
        handleFilesChange(files) {
            this.form.file = files;
        },
        handleFilesRemove(files) {
            this.form.file = files;
        },
        async save() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            const form = formPreparation({ ...this.form, file: this.form.file[0].file }, { imagesAsObject: true });

            await this.axios
                .post('/profile-documents', form)
                .then((res) => {
                    this.loading = false;
                    this.$router.push({ name: 'content-profile-documents' });
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>

<style></style>
