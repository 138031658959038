<template>
    <label :for="`${name}-${fileIdx}`" class="block mt-4" :title="name">
        <img v-if="type === 'pdf'" class="w-full h-full" src="@/assets/images/svg/file-icons/pdf.svg" alt="pdf" />

        <img
            v-else-if="type === 'image/png' || type === 'png' || type === 'jpg' || type === 'jpeg'"
            class="w-full h-full"
            src="@/assets/images/svg/file-icons/image.svg"
            alt="jpg"
        />

        <img v-else class="w-full h-full" src="@/assets/images/svg/file-icons/txt.svg" alt="txt" />
    </label>
</template>

<script>
export default {
    name: 'FileIconPreview',
    props: {
        fileIdx: {
            type: Number,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped></style>
