<template>
    <div
        class="file-upload relative min-h-[70px] py-3 px-4 rounded-md border transition"
        :class="{ 'form-input-error': error, 'border-gray-300 bg-white': !error }"
    >
        <div class="file-input-wrap absolute w-full h-full left-0 top-0">
            <input
                id="file"
                type="file"
                :title="getInputTitle"
                :multiple="multiple"
                class="file-input"
                @change="handleFileInput"
            />
        </div>

        <div class="flex items-center gap-3">
            <div class="w-[44px] h-[44px] rounded-[10px]">
                <slot name="icon">
                    <PaperclipIcon class="w-[44px] h-[44px]" />
                </slot>
            </div>

            <div class="flex-1-1">
                <slot name="text">
                    <h4 class="text-base leading-default">{{ label }}</h4>
                </slot>
            </div>
        </div>

        <div v-if="files && files?.length > 0" class="file-upload-content mt-3">
            <div class="flex flex-col gap-3">
                <div v-for="(file, fileIdx) in files" :key="fileIdx">
                    <div v-if="file.file" class="flex items-center gap-4">
                        <div class="file relative w-[68px] h-[84px]">
                            <input
                                :id="`${file.file.name}-${fileIdx}`"
                                type="file"
                                class="file-input"
                                :title="`Заменить ${file.file.name}`"
                                @change="handleFileInput"
                            />

                            <FileIconPreview
                                :name="`Заменить ${file.file.name}`"
                                :file-idx="fileIdx"
                                :type="file.file.type"
                            />

                            <button
                                type="button"
                                class="file-close absolute z-[2] right-0 top-0 text-black rounded-[4px]"
                                :title="`Удалить ${file.file.name}`"
                                @click="handleRemoveFile(file.file, fileIdx)"
                            >
                                <slot name="close">
                                    <XCircleIcon class="w-5 h-5" />
                                </slot>
                            </button>
                        </div>

                        <p class="text-sm font-medium mb-0">{{ file.file.name }}</p>
                    </div>

                    <div v-else class="flex items-center gap-4 relative z-[2]">
                        <div class="file relative w-[68px] h-[84px]">
                            <input
                                :id="`${file.name}-${fileIdx}`"
                                type="file"
                                class="file-input"
                                :title="`Заменить ${file.name}`"
                                @change="handleFileInput"
                            />

                            <FileIconPreview :name="`Заменить ${file.name}`" :file-idx="fileIdx" :type="file.type" />

                            <button
                                type="button"
                                class="file-close absolute z-[2] right-0 top-0 text-black rounded-[4px]"
                                :title="`Удалить ${file.name}`"
                                @click="handleRemoveFile(file, fileIdx)"
                            >
                                <slot name="close">
                                    <XCircleIcon class="w-5 h-5" />
                                </slot>
                            </button>
                        </div>

                        <a
                            :href="file.url"
                            target="_blank"
                            class="text-sm font-medium cursor-pointer underline mb-0"
                            :title="`Посмотреть ${file.name}`"
                        >
                            {{ file.name }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FileIconPreview from '@/components/base/file-upload/FileIconPreview.vue';

export default {
    name: 'FileUpload',
    components: { FileIconPreview },
    props: {
        label: {
            type: String,
            default: 'Загрузить документ',
        },
        files: {
            type: Array,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-files-change', 'on-files-remove'],
    computed: {
        getInputTitle() {
            if (this.files.length === 0 && this.multiple) return 'Добавить файлы';
            else if (this.files.length > 0 && this.multiple) return 'Заменить файлы';
            else if (this.files.length === 0) return 'Добавить файл';
            else return 'Заменить файл';
        },
    },
    methods: {
        handleFileInput(e) {
            const target = e.target;

            if (target.files === null) {
                return;
            }

            this.$emit('on-files-change', []);

            const filesArr = [];

            Array.from(target.files).forEach((file) => {
                filesArr.push({
                    file,
                });
            });

            this.$emit('on-files-change', filesArr);
        },
        handleRemoveFile(file, deletedFileIdx) {
            if (!this.files?.length) return;

            this.$emit(
                'on-files-remove',
                this.files.filter((i, idx) => idx !== deletedFileIdx)
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.file-input {
    @apply absolute block w-full h-full opacity-0 cursor-pointer;
}
</style>
