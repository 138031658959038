<template>
    <div class="dashboard filters col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 mt-2">
        <div class="intro-y mt-5">
            <div class="box p-5">
                <div class="grid grid-cols-12 gap-4 mb-8">
                    <div class="col-span-4">
                        <input
                            :value="startDate"
                            type="date"
                            class="form-control w-full"
                            autocomplete="off"
                            @input="(e) => handleDateChange(e, 'start_date')"
                        />
                    </div>

                    <div class="col-span-4">
                        <input
                            :value="endDate"
                            type="date"
                            class="form-control w-full"
                            autocomplete="off"
                            @input="(e) => handleDateChange(e, 'end_date')"
                        />
                    </div>

                    <div class="col-span-4">
                        <button type="button" class="btn btn-primary w-full" @click="handleSearchClick">Поиск</button>
                    </div>
                </div>

                <div v-if="isUserRoleRop">
                    <h2 class="text-2xl font-bold mb-5">Менеджеры</h2>

                    <ContentTable
                        :headers="tableRopStatisticHeaders"
                        :table-rows="tableRopStatisticData"
                        :loading="loading"
                        :table-actions="tableRopStatisticActions"
                        @show="linkWithQueryToOrders"
                    />
                </div>

                <div v-if="!isUserRoleRop" class="flex flex-wrap gap-6">
                    <button
                        v-for="(tab, tabData) in infoTabs"
                        :key="tabData"
                        type="button"
                        class="box border border-gray-300 p-5"
                        :class="{ 'bg-primary-1 text-white': tab.link === selectedInfoTab?.link }"
                        @click="handleInfoTabClick(tab)"
                    >
                        <strong>{{ tab.title }}</strong> {{ statistics?.[tab.countKey] || 0 }}
                    </button>
                </div>
            </div>

            <div v-if="infoTabsData?.data?.length" class="box p-5 mt-2">
                <ContentTable
                    :table-actions="null"
                    :headers="tableHeaders"
                    :table-rows="infoTabsData.data"
                    :meta="infoTabsData.meta"
                    :loading="loading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ContentTable from '@/components/tables/ContentTable.vue';

const inputDateFormat = 'YYYY-MM-DD';
const filtersDateFormat = 'DD.MM.YYYY';

export default {
    components: { ContentTable },
    data() {
        return {
            loading: false,
            info: {
                shipsNumber: 0,
                cruisesNumber: 0,
                ordersNumber: 0,
                pagesNumber: 0,
            },
            statistics: null,
            filters: {
                start_date: null,
                end_date: null,
            },
            infoTabs: [
                {
                    title: 'Новые пользователи:',
                    link: '/info/customers',
                    countKey: 'newUsers',
                },
                {
                    title: 'Новые заказы:',
                    link: '/info/orders',
                    countKey: 'newOrders',
                },
            ],
            selectedInfoTab: null,
            infoTabsData: null,

            tableRopStatisticHeaders: [
                { field: 'manager_name', label: 'ФИО менеджера' },
                { field: 'orders_quantity', label: 'Количество броней' },
                { field: 'paid_orders_quantity', label: 'Оплаченные брони' },
            ],
            tableRopStatisticData: [],
            tableRopStatisticActions: [
                { name: 'show', title: 'посмотреть заказы', icon: 'ShoppingCartIcon', cbName: 'show' },
            ],
        };
    },
    computed: {
        ...mapGetters({ isUserRoleRop: 'user/isUserRoleRop' }),
        startDate() {
            return this.filters.start_date
                ? dayjs(this.filters.start_date, filtersDateFormat).format(inputDateFormat)
                : null;
        },
        endDate() {
            return this.filters.end_date
                ? dayjs(this.filters.end_date, filtersDateFormat).format(inputDateFormat)
                : null;
        },
        preparedFilters() {
            const copy = { ...this.filters };

            for (const [k] of Object.entries(copy)) {
                if (k === 'start_date' || k === 'end_date') {
                    copy[k] = dayjs(copy[k], filtersDateFormat).format(inputDateFormat);
                }
            }

            if (this.$route?.query?.page) {
                copy.page = this.$route.query.page;
            }

            return copy;
        },
        tableHeaders() {
            if (this.selectedInfoTab.link === '/info/orders') {
                return [
                    { field: 'customer', label: 'Покупатель' },
                    { field: 'amount', label: 'Цена' },
                    { field: 'status', label: 'Статус' },
                ];
            }

            return [
                { field: 'first_name', label: 'Имя' },
                { field: 'last_name', label: 'Фамилия' },
                { field: 'email', label: 'Почта' },
                { field: 'phone', label: 'Телефон' },
            ];
        },
    },
    watch: {
        '$route.query.page': {
            handler: async function () {
                await this.fetchInfoTabsData(this.selectedInfoTab.link, this.preparedFilters);
            },
            deep: true,
            immediate: false,
        },
    },
    async created() {
        dayjs.extend(customParseFormat);

        this.setFilterDatesOnCreated();
        this.loading = true;
        await this.fetchStatisticsByRole();
        this.loading = false;
    },
    methods: {
        async fetchStatistics() {
            this.clearTabs();

            try {
                const { data } = await this.axios.get('/info/filter', {
                    params: {
                        start_date: this.filters.start_date,
                        end_date: this.filters.end_date,
                    },
                });

                if (data.success) {
                    this.statistics = data.data;
                } else {
                    throw new Error('Ошибка сервера');
                }
            } catch (ex) {
                console.log('cant fetch filter: ', ex);
            }
        },
        async fetchRopStatistics() {
            try {
                const { data } = await this.axios.get('/rop-statistic', {
                    params: {
                        start_date: this.filters.start_date,
                        end_date: this.filters.end_date,
                    },
                });

                if (!data.success || !data.data) throw new Error('Ошибка сервера');

                this.tableRopStatisticData = [];

                data.data.managers?.forEach((item) => {
                    const tableItem = {};

                    tableItem.managerId = item?.manager?.id;
                    tableItem.manager_name = item?.manager?.first_name;
                    if (item?.manager?.last_name) tableItem.manager_name += ` ${item?.manager?.last_name}`;
                    tableItem.orders_quantity = item?.orders_quantity;
                    tableItem.paid_orders_quantity = item?.paid_orders_quantity;

                    this.tableRopStatisticData.push(tableItem);
                });
            } catch (ex) {
                console.log('cant fetch filter: ', ex);
            }
        },
        async fetchStatisticsByRole() {
            if (this.isUserRoleRop) {
                await this.fetchRopStatistics();
            } else {
                await this.fetchStatistics();
            }
        },
        async handleSearchClick() {
            await this.fetchStatisticsByRole();
        },
        setFilterDatesOnCreated() {
            const startDate = this.$route.query.start_date || this.getTodayStringDate();
            const endDate = this.$route.query.end_date || null;

            this.filters.start_date = dayjs(startDate, filtersDateFormat).format(filtersDateFormat);
            this.filters.end_date = endDate
                ? dayjs(endDate, filtersDateFormat).format(filtersDateFormat)
                : dayjs().format(filtersDateFormat);
        },
        getTodayStringDate() {
            const today = dayjs();

            const day = '01';
            const month = today.month() + 1 < 10 ? `0${today.month() + 1}` : today.month() + 1;
            const year = today.year();

            return `${day}.${month}.${year}`;
        },
        handleDateChange(e, key) {
            const target = e.target;

            this.filters[key] = target.value ? dayjs(target.value, inputDateFormat).format(filtersDateFormat) : null;
        },
        async fetchInfoTabsData(url, filters) {
            try {
                const { data } = await this.axios.get(url, {
                    params: { ...filters },
                });
                this.infoTabsData = data;
            } catch (ex) {
                console.log('cant fetch info tabs data: ', ex);
            }
        },
        async handleInfoTabClick(tab) {
            this.selectedInfoTab = tab;
            await this.$router.push('/');
            await this.fetchInfoTabsData(tab.link, this.preparedFilters);
        },
        clearTabs() {
            this.selectedInfoTab = null;
            this.infoTabsData = null;
        },
        linkWithQueryToOrders(row) {
            const query = {
                managerId: row.managerId,
                start_date: this.filters.start_date,
                end_date: this.filters.end_date,
            };
            for (const key in query) {
                if (!query[key]) delete query[key];
            }

            this.$router.push({
                name: 'orders',
                query: query,
            });
        },
    },
};
</script>

<style scoped lang="scss"></style>
