import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import SideMenu from '@/layouts/side-menu/Main.vue';
import Login from '@/views/login/Main.vue';
import ErrorPage from '@/views/error-page/Main.vue';
import cityDirections from './pages/city-directions';
import cityArrivals from './pages/city-arrivals';
// import develop from './middlewares/develop';

const routes = [
    {
        path: '/',
        component: SideMenu,
        meta: {
            auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises', 'rop'],
        },
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises', 'rop'],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Main.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "users" */ '@/views/users/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin'],
                },
            },
            {
                path: '/users/create',
                name: 'userCreate',
                component: () => import(/* webpackChunkName: "user-create" */ '@/views/users/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin'],
                },
            },
            {
                path: '/users/:id/edit',
                name: 'userEdit',
                component: () => import(/* webpackChunkName: "user-edit" */ '@/views/users/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin'],
                },
            },
            {
                path: '/managers',
                name: 'managers',
                component: () => import(/* webpackChunkName: "managers" */ '@/views/managers/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/managers/create',
                name: 'managerCreate',
                component: () => import(/* webpackChunkName: "manager-create" */ '@/views/managers/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/managers/:id/edit',
                name: 'managerEdit',
                component: () => import(/* webpackChunkName: "manager-edit" */ '@/views/managers/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/customers',
                name: 'customers',
                component: () => import(/* webpackChunkName: "customers" */ '@/views/customers/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'rop'],
                },
            },
            {
                path: '/customers/create',
                name: 'customerCreate',
                component: () => import(/* webpackChunkName: "customer-create" */ '@/views/customers/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'rop'],
                },
            },
            {
                path: '/customers/:id/edit',
                name: 'customerEdit',
                component: () => import(/* webpackChunkName: "customer-edit" */ '@/views/customers/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'rop'],
                },
            },
            {
                path: '/orders',
                name: 'orders',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises', 'rop'],
                },
            },
            {
                path: '/ships',
                name: 'ships',
                component: () => import(/* webpackChunkName: "ships" */ '@/views/ships/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/ships/:id',
                name: 'ship',
                component: () => import(/* webpackChunkName: "ships" */ '@/views/ships/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
                children: [
                    {
                        path: '/ships/:id/edit',
                        name: 'ship-edit',
                        component: () => import(/* webpackChunkName: "ships" */ '@/views/ships/tabs/ship/Ship.vue'),
                        meta: {
                            auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                        },
                    },
                    {
                        path: '/ships/:id/decks',
                        name: 'decks',
                        component: () => import(/* webpackChunkName: "ships" */ '@/views/ships/tabs/deck/Deck.vue'),
                        meta: {
                            auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                        },
                    },
                    {
                        path: '/ships/:id/cabin-types',
                        name: 'cabin-types',
                        component: () =>
                            import(/* webpackChunkName: "ships" */ '@/views/ships/tabs/cabin-types/CabinType.vue'),
                        meta: {
                            auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                        },
                    },
                    {
                        path: '/ships/:id/cabins',
                        name: 'cabins',
                        component: () => import(/* webpackChunkName: "ships" */ '@/views/ships/tabs/cabins/Cabins.vue'),
                        meta: {
                            auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                        },
                    },
                ],
            },
            {
                path: '/offers',
                name: 'offers',
                component: () => import(/* webpackChunkName: "offers" */ '@/views/offers/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/offers/:id/edit',
                name: 'offerEdit',
                component: () => import(/* webpackChunkName: "offer-edit" */ '@/views/offers/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/offers/create',
                name: 'offerCreate',
                component: () => import(/* webpackChunkName: "offer-create" */ '@/views/offers/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/cruises',
                name: 'cruises',
                component: () => import(/* webpackChunkName: "cruises" */ '@/views/cruises/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/cruises/:cruise_id/edit',
                name: 'cruiseEdit',
                component: () => import(/* webpackChunkName: "cruise-edit" */ '@/views/cruises/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
                children: [
                    {
                        path: '/cruises/:cruise_id/edit/cruise-timetable',
                        name: 'cruise-timetable',
                        component: () => import(/* webpackChunkName: "cruises" */ '@/views/cruises/timetable/Main.vue'),
                        meta: {
                            auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                        },
                    },
                    {
                        path: '/cruises/:cruise_id/edit/cruise-timetable/:cruise_timetable_id/edit',
                        name: 'cruise-timetable-edit',
                        component: () =>
                            import(/* webpackChunkName: "cruise-edit" */ '@/views/cruises/timetable/Edit.vue'),
                        meta: {
                            auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                        },
                    },
                ],
            },
            {
                path: '/discounts',
                name: 'discounts',
                component: () => import(/* webpackChunkName: "discounts" */ '@/views/discounts/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/discounts/create',
                name: 'discount-create',
                component: () => import(/* webpackChunkName: "discounts" */ '@/views/discounts/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/discounts/:id/edit',
                name: 'discount-edit',
                component: () => import(/* webpackChunkName: "discounts" */ '@/views/discounts/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/special-discounts',
                name: 'special-discounts',
                component: () =>
                    import(/* webpackChunkName: "special-discounts" */ '@/views/special-discounts/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/special-discounts/create',
                name: 'special-discount-create',
                component: () =>
                    import(/* webpackChunkName: "special-discounts" */ '@/views/special-discounts/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/special-discounts/:id/edit',
                name: 'special-discount-edit',
                component: () =>
                    import(/* webpackChunkName: "special-discounts" */ '@/views/special-discounts/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/content/pages',
                name: 'pages',
                component: () => import(/* webpackChunkName: "cruises" */ '@/views/content/pages/ListPages.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/content/pages/create',
                name: 'page-create',
                component: () => import(/* webpackChunkName: "cruises" */ '@/views/content/pages/CreatePage.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/content/categories',
                name: 'page-categories',
                component: () =>
                    import(/* webpackChunkName: "cruises" */ '@/views/content/categories/ListPageCategories.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/content/categories/create',
                name: 'page-category-create',
                component: () => import('@/views/content/categories/CreatePageCategory.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/categories/:id/edit',
                name: 'page-category-edit',
                component: () => import('@/views/content/categories/EditPageCategory.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/banners',
                name: 'content-banners',
                component: () => import('@/views/content/banners/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/banners/create',
                name: 'content-banners-create',
                component: () => import('@/views/content/banners/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/banners/:id/edit',
                name: 'content-banners-edit',
                component: () => import('@/views/content/banners/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/banners/:id/edit',
                name: 'content-banners-edit',
                component: () => import('@/views/content/banners/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/profile-documents',
                name: 'content-profile-documents',
                component: () => import('@/views/content/profile-documents/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/profile-documents/create',
                name: 'content-profile-documents-create',
                component: () => import('@/views/content/profile-documents/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/content/profile-documents/:id/edit',
                name: 'content-profile-documents-edit',
                component: () => import('@/views/content/profile-documents/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/routes/points',
                name: 'points',
                component: () => import(/* webpackChunkName: "cruises" */ '@/views/routes/points/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/routes/points/create',
                name: 'point-create',
                component: () => import(/* webpackChunkName: "cruises" */ '@/views/routes/points/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/routes/points/:id/edit',
                name: 'point-edit',
                component: () => import(/* webpackChunkName: "cruises" */ '@/views/routes/points/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/reviews',
                name: 'reviews',
                component: () => import('@/views/reviews/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises', 'rop'],
                },
            },
            {
                path: '/reviews/:id/edit',
                name: 'review-edit',
                component: () => import('@/views/reviews/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises', 'rop'],
                },
            },
            {
                path: '/reviews/create',
                name: 'review-create',
                component: () => import('@/views/reviews/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises', 'rop'],
                },
            },
            {
                path: '/attractions',
                name: 'attractions',
                component: () => import('@/views/attractions/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/attractions/create',
                name: 'attraction-create',
                component: () => import('@/views/attractions/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/attractions/:id/edit',
                name: 'attraction-edit',
                component: () => import('@/views/attractions/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/popular-directions',
                name: 'popular-directions',
                component: () => import('@/views/popular-directions/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/popular-directions/create',
                name: 'popular-direction-create',
                component: () => import('@/views/popular-directions/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            {
                path: '/popular-directions/:id/edit',
                name: 'popular-direction-edit',
                component: () => import('@/views/popular-directions/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager'],
                },
            },
            ...cityDirections,
            ...cityArrivals,
            {
                path: '/indexes',
                name: 'indexes',
                component: () => import('@/views/indexes/Indexes.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/tariffs',
                name: 'tariffs',
                component: () => import('@/views/tariffs/Main.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/tariffs/create',
                name: 'tariffCreate',
                component: () => import('@/views/tariffs/Create.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
            {
                path: '/tariffs/:id/edit',
                name: 'tariffEdit',
                component: () => import('@/views/tariffs/Edit.vue'),
                meta: {
                    auth: ['super-admin', 'admin', 'manager', 'manager-agents', 'manager-cruises'],
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/error-page',
        name: 'error-page',
        component: ErrorPage,
    },
    {
        path: '/:pathMatch(.*)*',
        component: ErrorPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    // общедоступные роуты
    const allowed_routes = ['/login', '/:pathMatch(.*)*', '/error-page'];
    if (allowed_routes.includes(to.path)) {
        return next();
    }

    const token = localStorage.getItem('access_token');
    const currentUser = store.getters['user/isAuthenticated'];
    if (!token) {
        // если токена нет, кидаем на авторизацию
        return next({ path: '/login' });
    }
    if (!currentUser) {
        try {
            await store.dispatch('user/verifyToken');
        } catch (ex) {
            console.log('cant verify user by token: ' + ex);
        }
    }

    // получение ролей
    const userAccessRule = store.getters['user/getUserRole'];

    console.log('userAccessRule', userAccessRule);

    const { auth: pageRoles } = to.meta;

    console.log('pageRoles', pageRoles);

    if (pageRoles.length > 0) {
        if (pageRoles.includes(userAccessRule)) {
            return next();
        }
        console.log('ACCESS RULES REJECTED');
        return next({ name: 'error-page' });
    }

    next();
});

export default router;
